<template>
  <div v-if="$store.state.me.role !== 'partner'" class="tbl tbl--divided">
    <div class="tbl__header">
      <div class="tbl__filter">
        <label class="tbl__filter__item">
          <input @input="reloadTimeout" placeholder="Наименование" type="text" v-model="filter.q"/>
        </label>
        <label class="tbl__filter__item">
          <TagsInput @input="onTagsChanged"/>
        </label>
      </div>
      <div class="tbl__settings">
        <mq-layout :mq="[ 'lg', 'xlg', 'xl' ]">
          <a class="tbl__setting" href="javascript:void(0)">
            <SettingsIcon/>
          </a>
        </mq-layout>
        <div class="tbl__type">
          <a :class="{ active: $store.state.data.contracts.view === 'table'}" @click.prevent="$store.state.data.contracts.view = 'table'" href="#">
            <TypeTable/>
          </a>
          <a :class="{ active: $store.state.data.contracts.view === 'kanban'}" @click.prevent="$store.state.data.contracts.view = 'kanban'" href="#">
            <TypeKanban/>
          </a>
        </div>
      </div>
    </div>
    <div class="tbl__container">
      <kanban-board :blocks="blocks" :stages="stages" class="kanban-wrapper" id="contracts-kanban">
        <div :key="stage" :slot="stage" class="contracts-kanban__heading" v-for="stage in stages">
          <h3 class="contracts-kanban__heading__title">
            <span class="contracts-kanban__heading__title__text">{{ stage }}</span>
            <span class="contracts-kanban__heading__title__count">{{ getCountContractOfStatus(stage) }}</span>
          </h3>
          <div class="contracts-kanban__heading__price">{{ prettifyPrice(getSummContractOfStatus(stage)) }} руб</div>
        </div>
        <div :key="block.id" :slot="block.id" class="kanban-block" v-for="block in blocks">
          <router-link :to="{ name: 'contract', params: { id: block.id } }" class="contracts-kanban">
            <div class="contracts-kanban__header">
              <div class="contracts-kanban__header__item">{{ block.name }}</div>
              <div class="contracts-kanban__header__item">
                <i>
                  <ClockCircle/>
                </i>
                <span>{{ getDate(block.date) }}</span>
              </div>
            </div>
            <div class="contracts-kanban__body">
              <div class="contracts-kanban__partner">{{ block.partner && block.partner.name ? block.partner.name : '-' }}</div>
              <div class="contracts-kanban__property" v-if="block.property_name">{{ block.property_name }}</div>
              <div class="contracts-kanban__manager" v-if="block.manager && block.manager.name">{{ block.manager.name }}</div>
              <div class="contracts-kanban__price">{{ prettifyPrice(block.summ) }} руб</div>
            </div>
          </router-link>
        </div>
      </kanban-board>
      <scroll-loader :loader-disable="loadMore" :loader-method="nextPage">
        <div>Загрузка...</div>
      </scroll-loader>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import SettingsIcon from '@/components/svg/settings'
import TypeTable from '@/components/svg/TypeTable'
import TypeKanban from '@/components/svg/TypeKanban'
import TagsInput from '@/components/ui/GraphInputs/TagsInput'
import ClockCircle from '@/components/svg/ClockCircle'
import ALL_CONTRACTS from '@/graphql/queries/AllContracts.gql'
import { extendMoment } from 'moment-range'

const _moment = extendMoment(moment)
const Today = _moment()
const Tomorrow = _moment().add(1, 'days')
const Week = _moment().endOf('week')

export default {
  name: 'ContractsKanban',
  components: { ClockCircle, TagsInput, TypeKanban, TypeTable, SettingsIcon },
  props: {
    internet: {
      type: Boolean,
      required: true
    },
    callCenter: {
      type: Boolean,
      required: true
    }
  },
  data: () => {
    return {
      stages: ['Профинансирован', 'Согласование', 'Готов к финансированию'],
      blocks: [],
      perPage: 10,
      loadMore: false,
      prevLength: 0,
      timeout: null,
      page: 0,
      summ: {
        'Профинансирован': 0,
        'Согласование': 0,
        'Готов к финансированию': 0
      },
      count: {
        'Профинансирован': 0,
        'Согласование': 0,
        'Готов к финансированию': 0
      },
      filter: {
        q: '',
        tags: [],
        status_date: null
      }
    }
  },
  created () {
    this.loadContracts()
  },
  watch: {
    '$store.state.loadMoreContracts' () {
      if (this.$store.state.loadMoreContracts) {
        this.page++
      }
    },
    '$store.state.data.contracts.sort2' () {
      this.filter.status_date = this.getRange(this.$store.state.data.contracts.sort2)
      this.reload()
    },
    '$store.state.data.contracts.sortDate' () {
      this.filter.status_date = this.getRange(this.$store.state.data.contracts.sort2)
      this.reload()
    },
    'callCenter' (val) {
      this.reload()
    },
    'internet' (val) {
      this.reload()
    }
  },
  methods: {
    nextPage () {
      this.page++
      this.loadContracts()
    },
    loadContracts () {
      this.stages.forEach(stage => {
        let filter = this.getContractFilter()
        filter.status = stage
        this.$apollo.mutate({
          mutation: ALL_CONTRACTS,
          variables: {
            filter: filter,
            page: this.page,
            perPage: this.perPage,
            sortField: 'date',
            sortOrder: 'desc'
          },
          update: (store, { data }) => {
            // Блоки
            let currentBlocks = this.blocks
            this.blocks = currentBlocks.concat(data.allContracts.filter(i => currentBlocks.indexOf(i) === -1))

            // Суммы
            data.allContracts.forEach(element => {
              this.summ[stage] += Number(element.summ)
            })

            // Количество
            if (data._allContractsMeta && data._allContractsMeta.count) this.count[stage] = data._allContractsMeta.count
          }
        })
      })
    },
    getRange (status) {
      switch (status) {
        case 'today':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DDTHH:mm'),
            end: _moment(Today).startOf('day').add(1, 'days').format('YYYY-MM-DDTHH:mm')
          }
        case 'tomorrow':
          return {
            start: _moment(Tomorrow).startOf('day').format('YYYY-MM-DDTHH:mm'),
            end: _moment(Tomorrow).startOf('day').add(1, 'days').format('YYYY-MM-DDTHH:mm')
          }
        case 'week':
          return {
            start: _moment(Today).startOf('day').format('YYYY-MM-DDTHH:mm'),
            end: _moment(Week).endOf('week').add(1, 'days').format('YYYY-MM-DDTHH:mm')
          }
        case 'month':
          return {
            start: _moment(Today).startOf('month').format('YYYY-MM-DDTHH:mm'),
            end: _moment(Today).endOf('month').add(1, 'days').format('YYYY-MM-DDTHH:mm')
          }
        case 'period':
          return {
            start: _moment(this.$store.state.data.contracts.sortDate[0]).startOf('day').format('YYYY-MM-DDTHH:mm'),
            end: _moment(this.$store.state.data.contracts.sortDate[1]).endOf('day').format('YYYY-MM-DDTHH:mm')
          }
        default:
          return null
      }
    },
    getContractFilter () {
      let currentFilter = {}
      if (this.filter.q) currentFilter.q = this.filter.q
      if (this.filter.status_date) currentFilter.status_date = this.filter.status_date
      if (this.filter.tags.length) currentFilter.tags = this.filter.tags
      if (this.internet) currentFilter.internet = this.internet
      if (this.callCenter) currentFilter.callCenter = this.callCenter
      return currentFilter
    },
    reloadTimeout () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.reload()
      }, 500)
    },
    reload () {
      this.blocks = []
      this.summ = {
        'Профинансирован': 0,
        'Согласование': 0,
        'Готов к финансированию': 0
      }
      this.count = {
        'Профинансирован': 0,
        'Согласование': 0,
        'Готов к финансированию': 0
      }
      this.page = 0
      this.loadContracts()
    },
    onTagsChanged (tags) {
      this.filter.tags = tags.map(item => item.value)
      this.reload()
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString()
    },
    getDate (datetime) {
      return datetime ? moment(String(datetime)).format('DD.MM.YYYY') : ''
    },
    getCountContractOfStatus (status) {
      if (this.count[status] !== undefined) {
        return this.count[status]
      }
    },
    getSummContractOfStatus (status) {
      if (this.summ[status] !== undefined) {
        return this.summ[status]
      }
    }
  }
}
</script>

<style lang="stylus">
#contracts-kanban .drag-column-header
  padding 0

.contracts-kanban
  background: $white;
  border: 1px solid rgba($darkgray, 0.3);
  box-sizing: border-box;
  box-shadow: 0 7px 20px rgba($black, 0.06);
  border-radius: 10px;
  margin-bottom 10px
  display flex
  flex-direction column

  &__partner
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $black;
    margin-bottom 11px

  &__property
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $orange;
    margin-bottom 12px

  &__manager
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: $c4E555A;
    padding 6px 10px
    border: 1px solid $darkgray;
    box-sizing: border-box;
    border-radius: 6px;
    margin-bottom 15px

  &__body
    display flex
    flex-direction column
    align-items flex-start
    padding 8px 20px 15px

  &__header
    display flex
    align-items center
    padding 15px 20px
    border-bottom 1px solid $pinky
    justify-content space-between

    &__item
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: $c7A;
      display flex
      align-items center

      i
        margin-right 10px
        width 16px
        height 16px
        display flex
        align-items stretch
        justify-content stretch

        svg
          width 100%
          height 100%

  &__heading
    display flex
    align-items flex-start
    flex-direction column
    margin-bottom 20px

    &__price
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      color: $c4E555A;

    &__title
      display flex
      align-items center
      justify-content flex-start
      margin-bottom 13px

      &__text
        font-weight: 500;
        font-size: 17px;
        line-height: 21px;
        color: $black;

      &__count
        background $orange
        height 20px
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: $white;
        border-radius 30px
        padding 5px
        margin-left 5px

&__price
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: $c4E555A;
</style>
